import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Avatar from "../../src/components/avatar";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Welcome" mdxType="SEO" />
    <Avatar mdxType="Avatar" />
    <h1 {...{
      "id": "cam-howey"
    }}>{`Cam Howey`}</h1>
    <p><strong parentName="p">{`Brief Bio:`}</strong>{` Cam Howey is a retired engineer with a passion for applying big data to healthcare, generosity, gratitude, and kindness. He lives in Calgary, Alberta. His current focus of work has been analyzing data from the `}<a parentName="p" {...{
        "href": "https://www150.statcan.gc.ca/n1/en/catalogue/89F0115X"
      }}>{`Canadian General Social Survey`}</a>{` on giving, volunteering, and donation rates. He is an Excel magician and playful grandfather. He has been a `}<a parentName="p" {...{
        "href": "https://www.mindcamp.org/presenters/past-presenters/?leader=howey_cam"
      }}>{`presenter at Mindcamp Canada.`}</a></p>
    <p><strong parentName="p">{`Email:`}</strong>{` `}<a parentName="p" {...{
        "href": "mailto:cam.howey@gmail.com"
      }}>{`cam.howey@gmail.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      